import PORTALS from './portals';

export const POST_TYPES = {
  LANDING_PAGE: 'landingPage',
  CHANGE_LOCATION_PAGE: 'changeLocationPage',
  RESULTS_PAGE: 'searchResultsPage',
  DETAIL_PAGE: 'detailPage',
  RESULTS_CLICK: 'searchResultsClick',
  GENERIC_CLICK: 'searchGenericClick'
};

export const NAMESPACES = {
  [PORTALS.LOBBY]: {
    DEFAULT: 'fcoePageView',
    RESULTS_CLICK: 'fcoeFertilitySearchResultsClick',
    GENERIC_CLICK: 'fcoeFertilitySearchGenericClick'
  },
  [PORTALS.MYUHC]: {
    DEFAULT: 'fcoePageView',
    RESULTS_CLICK: 'fcoeFertilitySearchResultsClick',
    GENERIC_CLICK: 'fcoeFertilitySearchGenericClick'
  },
  DEFAULT: {
    DEFAULT: 'pageView',
    RESULTS_CLICK: 'fertilitySearchResultsClick',
    GENERIC_CLICK: 'fertilitySearchGenericClick'
  }
};

export const PAGE_DATA_LAYER_NAMES = {
  [PORTALS.LOBBY]: 'fcoePageDataLayer',
  [PORTALS.MYUHC]: 'fcoePageDataLayer',
  DEFAULT: 'pageDataLayer'
};

export const REGREX = {
  ZIP: /^ *[0-9]{5} *$/,
  CS: /^ *([a-z]+-? *){0,2}[a-z]+(, *| +)[a-z]{2} *$/i,
  CSZ: /^ *([a-z]+-? *){0,2}[a-z]+(, *| +)[a-z]{2} +[0-9]{5} *$/i
};

export const SEARCH_CATEGORY = {
  ZIP: 'zipcode',
  CS: 'city state',
  CSZ: 'city state zipcode',
  NA: ''
};
