module.exports = {
  libraryName: 'ProviderSearch',
  assetUrls: {
    local: 'http://localhost:9700/',
    dev: 'https://provider-search-dev.optum.com/',
    test: 'https://provider-search-test.optum.com/',
    stage: 'https://provider-search-stage.optum.com/etc/designs/provider-search-cdn/',
    production: 'https://provider-search.optum.com/etc/designs/provider-search-cdn/'
  }
};
