import { useRef, useState, useEffect } from 'react';
import { focusable } from 'tabbable';

const getActiveElementFocusIndex = rootNode => {
  const list = focusable(rootNode);
  return list.indexOf(document.activeElement);
};

const getActiveElementByFocusIndex = (rootNode, index) => {
  const list = focusable(rootNode);
  return list[index];
};

const defaultCallbackRefFn = null;

const useGnFocusRelease = (
  isActiveFocusTrap = true,
  focusOnClose = true,
  getFocusableListRoot = null
) => {
  const [releaseElement, setReleaseElement] = useState(void 0);
  const eventRef = useRef({});
  const focusCallbackRef = useRef({ fn: defaultCallbackRefFn });

  const onGnModalEvent = ({ detail = {} }) => {
    if (!releaseElement) {
      if (detail.expanded) {
        setReleaseElement(
          getFocusableListRoot
            ? { index: getActiveElementFocusIndex(getFocusableListRoot()) }
            : document.activeElement
        );
      }
    } else {
      if (!detail.expanded) {
        if (detail.releaseElement === releaseElement) {
          if (focusOnClose) {
            eventRef.current = { detail };
          } else {
            focusCallbackRef.current.fn = (returnElement = false) => {
              const el = getFocusableListRoot
                ? getActiveElementByFocusIndex(getFocusableListRoot(), detail.releaseElement.index)
                : detail.releaseElement;

              if (document.body.contains(el)) {
                if (returnElement) {
                  return el;
                } else {
                  el.focus();
                }
              } else {
                console.warn('Provider Search unable to regain focus');
              }
            };
          }
          setReleaseElement(null);
        }
      }
    }
  };

  useEffect(() => {
    if (!isActiveFocusTrap && !releaseElement && focusCallbackRef.current.fn) {
      focusCallbackRef.current.fn = defaultCallbackRefFn;
    }
  }, [isActiveFocusTrap]);

  useEffect(() => {
    if (isActiveFocusTrap || releaseElement) {
      document.addEventListener('GN_modalEvent', onGnModalEvent);
    }

    return () => {
      document.removeEventListener('GN_modalEvent', onGnModalEvent);
    };
  }, [isActiveFocusTrap, releaseElement]);

  useEffect(() => {
    const { detail } = eventRef.current;
    if (!releaseElement && focusOnClose && detail) {
      if (getFocusableListRoot) {
        const el = getActiveElementByFocusIndex(getFocusableListRoot(), detail.releaseElement.index);
        if (el) {
          setTimeout(function () { el.focus(); });
        } else {
          console.warn('Provider Search unable to regain focus');
        }
      } else {
        setTimeout(function () { detail.releaseElement.focus(); });
      }
      eventRef.current = {};
    }
  }, [releaseElement]);

  useEffect(() => {
    if (releaseElement) {
      setTimeout(function() {
        document.dispatchEvent(new CustomEvent('GN_focusReleaseEvent', {
          detail: {
            releaseElement
          }
        }));
      });
    }
  }, [releaseElement]);

  return {
    focusReleaseElement: releaseElement,
    focusCallbackRef
  };
};

export {
  useGnFocusRelease
};
