import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import Svgicon from '../svg-icon/svgicon';
import KEYS from '../../constants/keys';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import useLazyStyles from '../../utils/useLazyStyles';
import { useGnFocusRelease } from '../../utils/useGnFocusRelease';
import styles from './search.scss';

const Search = ({ aemContent, searchTerm, onSearch, isMobile, changeSearch, cancelChangeSearch }) => {
  const [searchText, setSearchText] = useState(searchTerm);
  const [isError, setError] = useState(false);
  const searchInputRef = useRef();
  let isActiveFocusTrap = isMobile && changeSearch;
  const { focusReleaseElement } = useGnFocusRelease(isActiveFocusTrap);
  isActiveFocusTrap &&= !focusReleaseElement;

  useLazyStyles(styles);

  useEffect(() => {
    if (changeSearch) {
      // Post analytics data for change location page
      postAnalyticsData(POST_TYPES.CHANGE_LOCATION_PAGE, { content: { pageName: 'change location' } });
    }
  }, [changeSearch]);

  const _searchClicked = (searchText) => {
    if (!isNullEmptyOrUndefined(searchText)) {
      setError(false);
      onSearch(searchText);
    } else {
      setError(true);
      searchInputRef.current && searchInputRef.current.focus();
      onSearch(null);
    }
  };

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, []);

  const _handleEscKey = e => {
    if (isMobile && e.key === KEYS.ESCAPE) {
      cancelChangeSearch();
    }
  };

  const _handleEnter = e => {
    if (e.key === KEYS.ENTER) {
      _searchClicked(searchText);
    }
  };

  const getSearchContainerProps = () => {
    if (!changeSearch) {
      return {};
    }

    return {
      'aria-labelledby': 'searchTitle',
      role: 'dialog',
      onKeyDown: _handleEscKey
    };
  };

  return (
    <FocusTrap active={isActiveFocusTrap} focusTrapOptions={{ initialFocus: `#searchInput` }}>
      <div className="searchContainer" {...getSearchContainerProps()}>
        {(isMobile && changeSearch) ? (<div className="searchTitleSection">
          <h1 className="searchTitle" id="searchTitle">{aemContent.searchTitle}</h1>
          <button className="closeSearch" onClick={cancelChangeSearch}>
            <Svgicon type="close" />
            <span className="sr-only">{aemContent.closeButton}</span>
          </button>
        </div>) : (<h1 className="searchTitle">{aemContent.searchTitle}</h1>)
        }

        <div className="searchArea">
          <label htmlFor="searchInput" className={classNames('searchLabel', { errorText: isError })}>
            {!isMobile && isError && (
              <Svgicon type="icon_error_circle" />
            )}
            <span>{aemContent.searchPlaceholder}</span>
          </label>

          {isMobile && ( // mobile
            <div className={classNames('searchText', { errorBorder: isError })} >
              <input id="searchInput"
                ref={searchInputRef}
                aria-describedby={isError ? 'searchError' : null}
                aria-invalid={isError ? 'true' : 'false'}
                aria-required="true"
                type="text"
                defaultValue={searchText}
                className={classNames('searchInput', { errorBorder: isError })}
                onChange={event => setSearchText(event.target.value)}
                onKeyDown={_handleEnter}>
              </input>
              <button
                className="mobileSearch"
                onClick={() => { _searchClicked(searchText); }}>
                <Svgicon type="search" />
                <span className="sr-only">{aemContent.searchButton}</span>
              </button>
            </div>
          )}

          {!isMobile && (  // desktop
            <div className={classNames('searchText', { errorBorder: isError })} >
              <Svgicon type="search" />
              <input id="searchInput"
                ref={searchInputRef}
                aria-describedby={isError ? 'searchError' : null}
                aria-invalid={isError ? 'true' : 'false'}
                aria-required="true"
                type="text"
                defaultValue={searchText}
                className={classNames('searchInput', { errorBorder: isError })}
                onChange={event => setSearchText(event.target.value)}
                onKeyDown={_handleEnter}>
              </input>
              <button
                className="PS_btn"
                onClick={() => { _searchClicked(searchText); }}>
                {aemContent.searchButton}
              </button>
            </div>
          )}

          {isError && (
            <div id="searchError" className="requiredField errorText">{aemContent.error}</div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};

Search.propTypes = {
  aemContent: PropTypes.object,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func,
  isMobile: PropTypes.bool,
  changeSearch: PropTypes.bool,
  cancelChangeSearch: PropTypes.func
};

Search.defaultProps = {
  aemContent: null,
  searchTerm: '',
  onSearch: () => null,
  isMobile: false,
  cancelChangeSearch: () => null,
  changeSearch: false
};

export default Search;
