import React from 'react';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './svgicon.scss';
import PropTypes from 'prop-types';

const Svgicon = ({ classes, isStaticMarkup, title, type }) => {
  useLazyStyles(styles, isStaticMarkup);

  switch (type) {
    case 'search':
      return (
        <svg className={`svgIcon searchIcon ${classes}`}
          width="24"
          height="26"
          viewBox="0 0 24 24"
          focusable="false"
          aria-hidden="true"
          title={title}
        >
          <path d="M10.426 2c4.593 0 8.317 3.529 8.317 7.882a7.59 7.59 0 0 1-1.737 4.822l5.91 5.602-1.492 1.415-5.91-5.602a8.576 8.576 0 0 1-5.088 1.646c-4.593 0-8.316-3.53-8.316-7.883S5.833 2 10.426 2zm0 2C7 4 4.22 6.634 4.22 9.882c0 3.25 2.779 5.883 6.206 5.883 3.428 0 6.206-2.634 6.206-5.883C16.632 6.634 13.854 4 10.426 4z"></path>
        </svg>
      );
    case 'arrow_left':
      return (
        <svg
          className={`svgIcon arrow_left ${classes}`}
          viewBox="0 0 12 24"
          focusable="false"
          aria-hidden="true"
          title={title}
        >
          <path
            d="M2.8 11l9.2 9.113-1.4 1.387L0 11 10.6.5 12 1.887z"
          />
        </svg>
      );
    case 'icon_error_circle':
      return (
        <svg
          className={`svgicon icon_error_circle ${classes}`}
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M12,1.5C6.2,1.5,1.5,6.3,1.5,12c0,5.9,4.7,10.5,10.5,10.5S22.5,17.9,22.5,12C22.5,6.3,17.9,1.5,12,1.5zM12.9,18.9c-0.3,0.3-0.6,0.3-0.9,0.3s-0.6-0.1-0.9-0.3s-0.4-0.6-0.4-0.9s0.1-0.6,0.4-0.9s0.4-0.6,0.9-0.6s0.8,0.1,0.9,0.5s0.4,0.6,0.4,0.9C13.3,18.3,13.2,18.6,12.9,18.9z M13.2,9.1l-0.3,4.2c0,0.4-0.1,0.9-0.3,1.2S12.3,15,12,15s-0.6-0.1-0.6-0.4s-0.3-0.6-0.4-1.2l-0.1-4.2c0-0.8-0.1-1.3-0.1-1.8s0.1-0.9,0.4-1.2s0.6-0.3,0.9-0.3c0.4,0,0.9,0.2,1.1,0.4s0.3,0.9,0.3,1.5C13.3,8.2,13.3,8.7,13.2,9.1z" />
        </svg>
      );
    case 'map_pin':
      return (
        <svg
          className={`svgicon map_pin ${classes}`}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M6 0a6 6 0 0 1 5.195 9.003c-.692 1.195-2.423 3.178-5.195 5.949l-.524-.527c-2.47-2.499-4.028-4.308-4.674-5.427A6 6 0 0 1 6 0zm0 1.5a4.5 4.5 0 0 0-3.9 6.748c.463.8 1.551 2.11 3.239 3.883l.66.686.447-.462c1.809-1.886 2.969-3.27 3.451-4.104A4.5 4.5 0 0 0 6 1.5zm0 2.25a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5zm0 1.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z" />
        </svg>
      );
    case 'new_window':
      return (
        <svg
          className={`svgicon new_window ${classes}`}
          width="12"
          height="12"
          viewBox="0 0 9 9"
          focusable="false"
          aria-hidden="true"
        >
          <path id="a" d="M7.25 2.821L1.776 8.295.716 7.234 6.2 1.75H2.75V.25h6.007l-.007 6h-1.5z" />
        </svg>
      );
    case 'website':
      return (
        <svg
          className={`svgicon website ${classes}`}
          width="22"
          height="22"
          viewBox="0 0 22 22"
          focusable="false"
          aria-hidden="true"
        >
          <path id="a" d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11c0-2.917-1.159-5.715-3.222-7.778C16.715 1.159 13.918 0 11 0zm7.322 17.236c-.714-.671-1.517-1.24-2.386-1.692.27-1.269.421-2.56.454-3.857h4.207c-.142 2.046-.94 3.992-2.275 5.549zm-16.92-5.549H5.61c.033 1.297.185 2.588.454 3.857-.87.452-1.672 1.02-2.386 1.692-1.335-1.557-2.133-3.503-2.275-5.549zm2.276-6.923c.714.671 1.517 1.24 2.386 1.692-.27 1.269-.421 2.56-.454 3.856H1.403c.142-2.045.94-3.99 2.275-5.548zm10.656.908c-2.137.808-4.496.808-6.634 0 .767-2.668 2.076-4.297 3.3-4.297 1.224 0 2.533 1.63 3.317 4.297h.017zm-.26-3.781c1.208.407 2.325 1.05 3.285 1.89-.54.501-1.138.938-1.78 1.3-.332-1.144-.846-2.227-1.523-3.208l.017.018zm-7.089 8.421c.03-1.107.153-2.21.368-3.296 2.357.852 4.937.852 7.294 0 .215 1.087.338 2.19.368 3.296h-8.03zm8.03 1.376c-.03 1.107-.153 2.21-.368 3.296-2.357-.849-4.937-.849-7.294 0-.215-1.087-.338-2.19-.368-3.296h8.03zM6.421 5.083c-.642-.363-1.24-.8-1.78-1.303.963-.85 2.086-1.5 3.303-1.911-.677.982-1.192 2.068-1.523 3.214zm0 11.832c.332 1.145.846 2.23 1.523 3.21-1.217-.41-2.34-1.058-3.303-1.907.54-.503 1.138-.94 1.78-1.303zm1.262-.588c2.138-.808 4.496-.808 6.634 0-.784 2.668-2.093 4.297-3.317 4.297-1.224 0-2.533-1.63-3.317-4.297zm7.906.588c.639.364 1.233.801 1.77 1.303-.963.85-2.086 1.5-3.303 1.911.677-.982 1.192-2.068 1.523-3.214h.01zm.801-6.604c-.033-1.296-.185-2.587-.454-3.856.87-.452 1.672-1.02 2.386-1.692 1.335 1.557 2.133 3.503 2.275 5.548H16.39" />
        </svg>
      );
    case 'phone':
      return (
        <svg
          className={`svgicon phone ${classes}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          aria-hidden="true"
        >
          <path id="a" d="M7.3 8.22c.509.723 1.084 1.406 1.722 2.045.883.883 1.855 1.646 2.899 2.281l1.151-1.151c.898-.899 2.36-.899 3.258 0l2.82 2.82c.898.898.898 2.36 0 3.258l-1.666 1.665c-.437.439-1.022.672-1.623.674-.092.016-.19.019-.29.008-3.901-.439-7.548-2.169-10.381-5.002C2.327 11.955.59 8.261.176 4.318c-.01-.098-.007-.19.008-.278.023-.57.256-1.115.67-1.529L2.52.842c.898-.896 2.36-.896 3.257 0l2.82 2.82c.898.899.899 2.362 0 3.259L7.3 8.22zM5.464 7.215c.023-.014.041-.029.053-.04l1.667-1.668c.117-.117.117-.313 0-.43l-2.82-2.82c-.116-.116-.313-.116-.43 0L2.269 3.925c-.061.061-.092.144-.086.233.003.04.003.08.002.118.398 3.43 1.924 6.633 4.42 9.128 2.45 2.45 5.583 3.967 8.945 4.398.082-.008.167-.007.256.006.102.015.197-.016.264-.083l1.667-1.667c.117-.117.117-.312 0-.43l-2.82-2.82c-.117-.116-.313-.116-.43 0l-1.626 1.625c-.328.373-.839.465-1.25.241-1.458-.792-2.804-1.797-4.003-2.995-.922-.924-1.73-1.933-2.412-3.012-.318-.431-.283-1.106.269-1.452z" />
        </svg>
      );
    case 'directions':
      return (
        <svg
          className={`svgicon directions ${classes}`}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M12 0L0 12l12 12 12-12L12 0zM1.697 12L12 1.697 22.303 12 12 22.303 1.697 12z" />
          <path id="a" d="M14.213 7.514L13.387 8.386 15.303 10.2 9.3 10.2 9.3 16.2 10.5 17.4 10.5 11.4 15.394 11.4 13.365 13.537 14.235 14.363 17.649 10.769z" />
        </svg>
      );
    case 'no_results':
      return (
        <svg
          className={`svgicon no_results ${classes}`}
          width="64"
          height="64"
          viewBox="0 0 64 64"
          focusable="false"
          aria-hidden="true"
        >
          <g fillRule="evenodd">
            <path fill="#D13F44" d="M29.87 6.49V2.13a2.13 2.13 0 1 1 4.26 0v4.36c12.434 1.024 22.342 10.942 23.355 23.378h4.384a2.132 2.132 0 0 1 0 4.262h-4.388c-1.037 12.41-10.936 22.302-23.35 23.325v4.414a2.13 2.13 0 1 1-4.262 0v-4.414C17.455 56.432 7.556 46.541 6.52 34.13H2.131a2.13 2.13 0 1 1 0-4.262h4.384C7.528 17.432 17.436 7.514 29.869 6.49zm22.967 25.138c0 11.715-9.494 21.21-21.21 21.21-11.714 0-21.208-9.495-21.208-21.21 0-11.715 9.494-21.21 21.209-21.21s21.21 9.495 21.21 21.21z" />
            <text fill="#6B6B6B" fontSize="40" fontWeight="500" letterSpacing="-.8">
              <tspan x="24" y="45">!</tspan>
            </text>
          </g>
        </svg>
      );
    case 'close':
      return (
        <svg
          className={`svgicon close ${classes}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M17 5v10h10v2H17v10h-2V17H5v-2h10V5h2z" transform="translate(-4 -4)" />
        </svg>
      );
    case 'retry':
      return (
        <svg
          className={`svgicon retry ${classes}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M12.756 1.75L17 5.993l-4.244 4.244-1.414-1.414L13.163 7H12.5C8.91 7 6 9.91 6 13.5S8.91 20 12.5 20s6.5-2.91 6.5-6.5h2c0 4.694-3.806 8.5-8.5 8.5S4 18.194 4 13.5 7.806 5 12.5 5h.673L11.34 3.167l1.416-1.417z" />
        </svg>
      );
    default:
      return null;
  }

};

Svgicon.defaultProps = {
  isStaticMarkup: false
};

Svgicon.propTypes = {
  classes: PropTypes.string,
  isStaticMarkup: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Svgicon;
