const KEYS  = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  ESC: 'Esc',
  SPACE: ' ',
  LEFT: 'Left',
  ARROW_LEFT: 'ArrowLeft',
  RIGHT: 'Right',
  ARROW_RIGHT: 'ArrowRight',
  UP: 'Up',
  ARROW_UP: 'ArrowUp',
  DOWN: 'Down',
  ARROW_DOWN: 'ArrowDown'
};

export default KEYS;

export const isKey = (test, value) => {
  switch (test) {
    case KEYS.ENTER:
      return value === KEYS.ENTER;
    case KEYS.ESCAPE:
    case KEYS.ESC:
      return value === KEYS.ESCAPE || value === KEYS.ESC;
    case KEYS.SPACE:
      return value === KEYS.SPACE;
    case KEYS.LEFT:
    case KEYS.ARROW_LEFT:
      return value === KEYS.LEFT || value === KEYS.ARROW_LEFT;
    case KEYS.RIGHT:
    case KEYS.ARROW_RIGHT:
      return value === KEYS.RIGHT || value === KEYS.ARROW_RIGHT;
    case KEYS.UP:
    case KEYS.ARROW_UP:
      return value === KEYS.UP || value === KEYS.ARROW_UP;
    case KEYS.DOWN:
    case KEYS.ARROW_DOWN:
      return value === KEYS.DOWN || value === KEYS.ARROW_DOWN;
    default:
      return false;
  }
};
