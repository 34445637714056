import axios from 'axios';
import { setDataStore, getDataStore } from '../utils/storageUtils';
import { isNullEmptyOrUndefined } from '../utils/defUtils';
import uiConstants from '../constants/ui';
import url from 'url';
import bigPinUrl from '../../../public/assets/images/big-pin.png';
import { Buffer } from 'buffer';

export default class GoogleMapService {

  constructor(configs) {
    this.configs = configs;
  }

  _buildStorageKey = () => 'ogn.providerSearch.googleMap';
  _buildCoordinatesString = (coordinates) => `${coordinates.latitude},${coordinates.longitude}`;

  _buildParams = (coordinatesString, marker, containerSize, scale) => {
    // stage icon is accessible to google api for local/dev/test
    let markerUrl = this.configs.staticPinUrl;
    markerUrl = markerUrl
    ? url.resolve(markerUrl, 'assets/images/big-pin.png')
    : bigPinUrl;

    return {
      center: coordinatesString,
      zoom: marker ? 15 : 3,
      scale: `${scale}`,
      markers: marker ? `icon:${markerUrl}|size:large|color:${uiConstants.googleMapsPinColor}|${coordinatesString}` : '',
      size: `${containerSize.width}x${containerSize.height}`,
      maptype: 'roadmap',
      key: this.configs.publicKey
    };
  };

  async _request(coordinates, marker, containerSize, scale) {
    try {
      const storageKey = this._buildStorageKey();
      const coordinatesString = this._buildCoordinatesString(
        !isNullEmptyOrUndefined(coordinates) ? coordinates : this.configs.mapsCoordinates
      );

      let content = getDataStore(coordinatesString, storageKey);

      if (isNullEmptyOrUndefined(content)) {
        const path = this.configs.mapsApiUrl;
        const params = this._buildParams(coordinatesString, marker, containerSize, scale);

        content = await axios.get(path, {
          params,
          responseType: 'arraybuffer'
        });

        if (isNullEmptyOrUndefined(content.data)) {
          throw 'Invalid response';
        }
        
const map = `data:image/png;base64, ${Buffer.from(content.data, 'binary').toString('base64')}`;
setDataStore(coordinatesString, map, storageKey);
        return map;
      } else {
        return Promise.resolve(content);
      }
    } catch (e) {
      return Promise.resolve('');
    }
  }

  async execute(coordinates, marker, containerSize, scale) {
    const content = await this._request(coordinates, marker, containerSize, scale);
    return content;
  }
}
