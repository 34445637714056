import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useIsMobile } from '../utils/useIsMobile.js';
import PropTypes from 'prop-types';
import AEMContentService from '../services/aemContent';
import SearchContentService from '../services/searchContent';
import Search from './search/search.js';
import StaticMap from './staticMap/staticMap';
import BackButton from './backButton/backButton';
import SearchResults from './searchResults/searchResults.js';
import SearchDetails from './searchDetails/searchDetails.js';
import MapContainer from './mapContainer/mapContainer.js';
import classNames from 'classnames';
import { isNullEmptyOrUndefined } from '../utils/defUtils.js';
import uiConstants from '../constants/ui';
import { postAnalyticsData, initializePageDataLayer } from '../utils/analytics-utils';
import { POST_TYPES } from '../constants/analytics';
import PORTALS from '../constants/portals';
import useLazyStyles from '../utils/useLazyStyles';
import styles from './searchRoot.scss';

const SearchRoot = ({ brand, token, portal, language, glFullPageContainer,aemContentUrl,searchContentUrl, stargateApiUrl, googleMapsConfigs }) => {
  const mainRef = useRef(null);
  const [searchContent, setSearchContent] = useState('');
  const [hasContent, setHasContent] = useState(false);
  const [aemContent, setAEMContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [providerCenter, setProviderCenter] = useState({});
  const [activeProvider, setActiveProvider] = useState({});
  const [changeSearch, setChangeSearch] = useState(false);
  const [isChangeSearchVisited, setChangeSearchVisited] = useState(false);
  const [mapDetailsRedirect, setMapDetailsRedirect] = useState(false);
  const isMobile = useIsMobile();
  const isSearchShown = !isMobile || (isMobile && !hasContent);
  const isResultsMobile = isMobile && hasContent;
  const isResultsDesktop = !isMobile && hasContent;
  const isStaticMap = !hasContent;
  const showBackButton = !providerCenter.address;
  const deviceType = /Mac/.test(navigator.userAgent) ? 'ios' : 'android';
  const linkNamePrefix = [PORTALS.SHAREPOINT,PORTALS.LOBBY,PORTALS.MYUHC].includes(portal) ? portal : '';

  useLazyStyles(styles);

  useLayoutEffect(() => {
    if (mainRef.current && glFullPageContainer) {
      // app height = viewport height minus gn header height (0 when not available); gn footer visible on scroll
      const setAppHeight = (e = null, isZeroOffset = false) => {
        mainRef.current.style.height = `calc(100vh - ${isZeroOffset ? 0 : glFullPageContainer.offsetTop}px)`;
      };

      if (window.gnAppReady && window.gnAppReady()) {
        setAppHeight(null);
      } else {
        setAppHeight(null, true);
        document.addEventListener('gnInitialized', setAppHeight);
      }

      return () => {
        document.removeEventListener('gnInitialized', setAppHeight);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    const _service = new AEMContentService(aemContentUrl);
    _service.execute(portal, language).then((response) => {
      setAEMContent(response);
    });
  }, []);

  const _hideDetails = () => {
    setProviderCenter({});
  };

  useEffect(() => {
    if (aemContent) {
      // Initialize pageDataLayer before posting analytics data
      initializePageDataLayer(portal, aemContent.strings.analytics.portals, token);

      // Post analytics data for landing page
      postAnalyticsData(POST_TYPES.LANDING_PAGE, { content: { pageName: 'landing' } });
    }
  }, [aemContent]);

  const _handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (!isNullEmptyOrUndefined(searchTerm)) {
      const _service = new SearchContentService(searchContentUrl, stargateApiUrl);

      _service.execute(portal, language, token, searchTerm, aemContent.config).then((content) => {
        setSearchContent(content);
        setHasContent(!isNullEmptyOrUndefined(content));
        setChangeSearch(false);
      });
    } else {
      _changeSearch();
      setHasContent(false);
    }
  };

  const _changeSearch = () => {
    setHasContent(false);
    setChangeSearch(true);
    setChangeSearchVisited(true);

    // Post analytics data on search change
    postAnalyticsData(POST_TYPES.GENERIC_CLICK, {
      search: { linkName: `${linkNamePrefix}fertility search:location change initiate` }
    });
  };

  const _cancelChangeSearch = () => {
    setHasContent(true);
  };

  const retrySearch = () => {
    _handleSearch(searchTerm);
  };

  const onClickBack = () => {
    if (isChangeSearchVisited) {
      _changeSearch();
    } else {
      setHasContent(false);
    }
  };


  const mainStyles = classNames(`ogn_PS ${brand} searchRoot`, {
    searchResultGrid: hasContent,
    staticImgGrid: !hasContent
  });

  return (
    <main ref={mainRef} role="main" id="searchRoot" className={mainStyles}>
      {aemContent && aemContent.strings &&
        <>
          {isStaticMap && <div className="PS_modalBackdrop"></div>}
          {isSearchShown &&
            <Search
              aemContent={changeSearch ? aemContent.strings.changeSearch : aemContent.strings.search}
              cancelChangeSearch={_cancelChangeSearch}
              changeSearch={changeSearch}
              isMobile={isMobile}
              onSearch={_handleSearch}
              searchTerm={searchTerm}
            />
          }
          {isResultsDesktop &&
            <>
              <SearchResults
                activeProvider={activeProvider}
                aemConfig={aemContent.config}
                aemContent={aemContent.strings}
                changeSearch={changeSearch}
                isMobile={isMobile}
                mapDetailsRedirect={mapDetailsRedirect}
                onChangeSearch={_changeSearch}
                providerCenter={providerCenter}
                retrySearch={retrySearch}
                searchContent={searchContent}
                searchTerm={searchTerm}
                setActiveProvider={setActiveProvider}
                setMapDetailsRedirect={setMapDetailsRedirect}
                setProviderCenter={setProviderCenter}
              />
              <MapContainer
                activeProvider={activeProvider}
                aemContent={aemContent.strings}
                initialLocation={aemContent.config.initialLocation}
                isMobile={isMobile}
                providerCenter={providerCenter}
                results={searchContent.results}
                setActiveProvider={setActiveProvider}
                setMapDetailsRedirect={setMapDetailsRedirect}
                setProviderCenter={setProviderCenter}
                publicKey={googleMapsConfigs.publicKey}
              />
            </>
          }
          {isResultsMobile &&
            <>
              {showBackButton &&
                <BackButton
                  srText={isChangeSearchVisited
                    ? aemContent.strings.common.backToChangeSearch
                    : aemContent.strings.common.backToSearch}
                  onClickBack={onClickBack}
                />
              }
              <MapContainer
                activeProvider={activeProvider}
                aemContent={aemContent.strings}
                initialLocation={aemContent.config.initialLocation}
                isMobile={isMobile}
                providerCenter={providerCenter}
                results={searchContent.results}
                setActiveProvider={setActiveProvider}
                publicKey={googleMapsConfigs.publicKey}
              />
              <SearchResults
                activeProvider={activeProvider}
                aemConfig={aemContent.config}
                aemContent={aemContent.strings}
                changeSearch={changeSearch}
                isMobile={isMobile}
                mapDetailsRedirect={mapDetailsRedirect}
                onChangeSearch={_changeSearch}
                providerCenter={providerCenter}
                retrySearch={retrySearch}
                searchContent={searchContent}
                searchTerm={searchTerm}
                setActiveProvider={setActiveProvider}
                setMapDetailsRedirect={setMapDetailsRedirect}
                setProviderCenter={setProviderCenter}
              />
            </>
          }
          {!isNullEmptyOrUndefined(providerCenter.address) &&
            <SearchDetails
              aemContent={aemContent.strings.details}
              aemCommon={aemContent.strings.common}
              aemConfig={aemContent.config}
              onHideDetails={_hideDetails}
              providerCenter={providerCenter}
              isMobile={isMobile}
              mapDetailsRedirect={mapDetailsRedirect}
              linkNamePrefix={linkNamePrefix}
              deviceType={deviceType}
              googleMapsConfigs={googleMapsConfigs}
            />
          }
          {isStaticMap && (
            isMobile
              ? <img className="staticFullMap" src={uiConstants.googleMapMobileImg} alt="" />
              : <StaticMap cssClasses="staticFullMap" language={language} scale={2} googleMapsConfigs={googleMapsConfigs} />
          )}
        </>
      }
    </main>
  );
};

SearchRoot.propTypes = {
  brand: PropTypes.string,
  language: PropTypes.string,
  portal: PropTypes.string,
  token: PropTypes.string,
  glFullPageContainer: PropTypes.instanceOf(Element)
};

SearchRoot.defaultProps = {
  brand: 'optum',
  token: '',
  portal: PORTALS.DEFAULT,
  language: 'en',
  glFullPageContainer: null
};

export default SearchRoot;
