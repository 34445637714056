import { useState, useEffect } from 'react';
import uiConstants from '../constants/ui';

export const useIsMobile = () => {
  function getMobile() {
    return window.innerWidth < uiConstants.mobileBreakpoint;
  }

  const [isMobile, setIsMobile] = useState(getMobile);

  useEffect(() => {
    function handleResize() {
      setIsMobile(getMobile());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default {
  useIsMobile
};
