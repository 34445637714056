import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Svgicon from '../svg-icon/svgicon';
import KEYS, { isKey } from '../../constants/keys';
import FocusTrap from 'focus-trap';
import {
  formatPhoneNumber,
  findElementByTextContent,
  getElementPromise
} from '../../utils/uiUtils';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import uiConstants from '../../constants/ui';
import activeMarkerUrl from '../../../../public/assets/images/big-pin.svg';
import markerUrl from '../../../../public/assets/images/pin.svg';
import {
  initMapTextLinkA11y,
  initGoogleLogoA11y
} from './mapContainerUtils';
import useLazyStyles from '../../utils/useLazyStyles';
import { useGnFocusRelease } from '../../utils/useGnFocusRelease';
import styles from './mapContainer.scss';

const googleLogoSel = 'img[src*="images/google"]';
const googleMapLinkSel = '.gm-style-cc a';
const focusTrapContainerSel = '.gm-style-iw.gm-style-iw-c';
const getFocusableListRoot = () => document.querySelector(focusTrapContainerSel);

export const MapContainer = ({
  activeProvider,
  aemContent,
  google,
  initialLocation,
  isMobile,
  providerCenter,
  results,
  setActiveProvider,
  setMapDetailsRedirect,
  setProviderCenter
}) => {
  if (!google) {
    return <div />;
  }

  const [activeMarker, setActiveMarker] = useState(null);
  const [focusTrap, setFocusTrap] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [isActiveFocusTrap, setIsActiveFocusTrap] = useState(false);
  const intervalIdsRef = useRef([]);
  const initialLatitude = parseFloat(initialLocation.latitude);
  const initialLongitude = parseFloat(initialLocation.longitude);
  const activeMarkerIcon = { url: activeMarkerUrl, scaledSize: new google.maps.Size(32, 40) };
  const markerIcon = { url: markerUrl, scaledSize: new google.maps.Size(16, 16) };
  const {
    focusReleaseElement,
    focusCallbackRef
  } = useGnFocusRelease(isActiveFocusTrap, false, getFocusableListRoot);

  const detailsLinkId = uiConstants.a11yDetailsPreviewDialogLinkId;
  const previewHeadingId = uiConstants.a11yDetailsPreviewDialogHeadingId;
  const getDefaultFocusTrapInitialFocus = () => `#${previewHeadingId}`;
  const getDetailsFocusTrapInitialFocus = () => `#${detailsLinkId}`;
  const getReleaseFocusTrapInitialFocus = isActiveFocusTrap => {
    const fn = focusCallbackRef.current.fn;
    if (isActiveFocusTrap && fn) {
      return () => fn(true);
    } else {
      return `#${previewHeadingId}`;
    }
  };
  const [getFocusTrapInitialFocus, setFocusTrapInitialFocus] = useState(() => getDefaultFocusTrapInitialFocus);

  useLazyStyles(styles);

  /* istanbul ignore next  */
  useEffect(() => {
    setFocusTrapInitialFocus(() => getDefaultFocusTrapInitialFocus);
    if (!activeProvider.address && (isActiveFocusTrap || focusTrap)) {
      setIsActiveFocusTrap(false);
      setFocusTrap(null);
    }
  }, [activeProvider]);

  /* istanbul ignore next  */
  useEffect(() => {
    if (focusReleaseElement !== void 0) {
      if (isActiveFocusTrap === !!focusReleaseElement) {
        setIsActiveFocusTrap(!focusReleaseElement);
        if (focusReleaseElement) {
          setFocusTrapInitialFocus(() => getReleaseFocusTrapInitialFocus);
        }
      }
    }
  }, [focusReleaseElement]);

  /* istanbul ignore next  */
  useEffect(() => {
    if (providerCenter.address) {
      isActiveFocusTrap && setIsActiveFocusTrap(false);
    } else if (activeProvider.address) {
      !isActiveFocusTrap && setIsActiveFocusTrap(true);
      focusTrap && setFocusTrapInitialFocus(() => getDetailsFocusTrapInitialFocus);
    }
  }, [providerCenter]);

  /* istanbul ignore next  */
  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('keydown', _handleEscapeKey);
    }

    return () => {
      if (!isMobile) {
        window.removeEventListener('keydown', _handleEscapeKey);
      }
      intervalIdsRef.current.forEach(clearInterval);
    };
  }, []);

  /* istanbul ignore next  */
  useEffect(() => {
    setActive(null);
    calcMapCenter();
  }, [results]);

  /* istanbul ignore next  */
  useEffect(() => {
    calcMapCenter();
  }, [mapInstance]);

  const onReadyMap = (mapProps, map) => {
    const srText = aemContent.common.openNewWindow;

    const { id: touIntervalId, promise: touPromise } = getElementPromise(
      () => findElementByTextContent(googleMapLinkSel, 'Terms of Use')
    );
    touPromise.then(el => { initMapTextLinkA11y(el, srText); });

    const { id: reportErrorIntervalId, promise: reportErrorPromise } = getElementPromise(
      () => findElementByTextContent(googleMapLinkSel, 'Report a map error')
    );
    reportErrorPromise.then(el => { initMapTextLinkA11y(el, srText); });

    const { id: googleLogoIntervalId, promise: googleLogoPromise } = getElementPromise(
      () => document.querySelector(googleLogoSel)
    );
    googleLogoPromise.then(initGoogleLogoA11y);

    intervalIdsRef.current = [ ...intervalIdsRef.current,
      touIntervalId,
      reportErrorIntervalId,
      googleLogoIntervalId
    ];

    setMapInstance(map);
  };

  const onClickMarker = (marker, props) => {
    setActive(props);
  };

  const onCloseDialog = () => {
    focusTrap && focusTrap.deactivate();
    setIsActiveFocusTrap(false);
    setFocusTrap(null);
    setActiveMarker(null);
    activeProvider.address && setActiveProvider({});
  };

  const onOpenDialog = () => {
    if (!focusTrap) {
      setIsActiveFocusTrap(true);
      addCloseSrOnlyText();
      initFocusTrap(true);
      initDetailsLink();
    } else if (!providerCenter.address) {
      focusTrap.deactivate();
      addCloseSrOnlyText();
      initFocusTrap(isActiveFocusTrap);
      initDetailsLink();
    } else if (!isActiveFocusTrap) {
      focusTrap.deactivate();
    }
  };

  const onClickMap = () => {
    if (!isMobile) {
      onCloseDialog();
    }
  };

  const onDragendMap = () => {
    onClickMap();
  };

  const onClickDetailsLink = e => {
    setIsActiveFocusTrap(false);
    setMapDetailsRedirect(true);
    setProviderCenter(activeProvider);
    e.preventDefault();

    // Post analytics data on opening details page(with map interaction- desktop)
    postAnalyticsData(POST_TYPES.RESULTS_CLICK, {
      search: {
        searchResultTitle: activeProvider.name,
        searchResultArea: 'map'
      }
    });
  };

  const _handleEscapeKey = e => {
    if (isKey(KEYS.ESCAPE, e.key)) {
      setActive(null);
    }
  };

  const calcMapCenter = () => {
    const bounds = new google.maps.LatLngBounds();
    results.forEach(marker => {
      bounds.extend({ lat: parseFloat(marker.location.latitude), lng: parseFloat(marker.location.longitude) });
    });
    setMapCenter(bounds.getCenter());
    if (mapInstance) {
      mapInstance.fitBounds(bounds);
      mapInstance.panToBounds(bounds);
    }
  };

  const setActive = (props) => {
    setActiveMarker(props);
    setActiveProvider(isNullEmptyOrUndefined(props) ? {} : props.item);
  };

  const addCloseSrOnlyText = () => {
    const closeIcon = document.querySelector('button.gm-ui-hover-effect > img');
    if (closeIcon) {
      closeIcon.insertAdjacentHTML('afterend', `<span class="sr-only">${aemContent.details.close}</span>`);
    }
  };

  const initDetailsLink = () => {
    const el = document.getElementById(detailsLinkId);
    if (el) {
      el.addEventListener('click', onClickDetailsLink);
    }
  };

  const initFocusTrap = (isActive = true) => {
    const trap = FocusTrap(focusTrapContainerSel, {
      initialFocus: getFocusTrapInitialFocus(isActive),
      clickOutsideDeactivates: true
    });
    setFocusTrap(trap);
    if (isActive) {
      trap.activate();
    }
  };

  const isVisibleInfoWindow = !!activeProvider.address && !isNullEmptyOrUndefined(activeMarker) && !isMobile;

  return (
    <Map
      className={'mapContainer'}
      google={google}
      initialCenter={{
        lat: initialLatitude,
        lng: initialLongitude
      }}
      center={mapCenter}
      zoom={parseInt(initialLocation.zoom)}
      mapTypeControl={false}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      fullscreenControl={false}
      onReady={onReadyMap}
      onClick={onClickMap}
      onDragend={onDragendMap}
    >
      {results && results.map((item, index) => {
        const icon = item.address === activeProvider.address
          ? activeMarkerIcon
          : markerIcon;

        return (
          <Marker
            name={item.name}
            title={item.name}
            position={{ lat: parseFloat(item.location.latitude), lng: parseFloat(item.location.longitude) }}
            key={index}
            icon={icon}
            optimized={false}
            item={item}
            onClick={onClickMarker}
          />);
      })}
      <InfoWindow
        marker={activeMarker}
        visible={isVisibleInfoWindow}
        options={{ disableAutoPan: false }}
        onClose={onCloseDialog}
        onOpen={onOpenDialog}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        {activeProvider.address && !isMobile ? (
          <section
            aria-labelledby={previewHeadingId}
            className="infoWindow noFocus"
            role="dialog"
          >
            <h3
              className="infoTitle noFocus"
              id={previewHeadingId}
              tabIndex="-1"
            >{activeProvider.name}</h3>
            <p className="infoDetails">
              {activeProvider.address.addressLine1}<br />
              {activeProvider.address.addressLine2 && (
                <>
                  {activeProvider.address.addressLine2}<br />
                </>
              )}
              {activeProvider.address.city},&nbsp;
              {activeProvider.address.state}&nbsp;
              {activeProvider.address.zipCode}
            </p>
            <a tabIndex="-1" href={`tel:${activeProvider.phone}`} className="infoDetails">
              {isNullEmptyOrUndefined(activeProvider.phone) ? '' : formatPhoneNumber(activeProvider.phone)}
              <span className="sr-only">{aemContent.map.phone}</span>
            </a>
            <a id={detailsLinkId} href="#" className="infoLink">
              <span>{aemContent.map.details}</span>
              <Svgicon type="arrow_left" classes="arrow_right" isStaticMarkup={true} />
            </a>
          </section>
        ) : <div></div>}
      </InfoWindow>
    </Map>
  );
};

const aemContentDefaultProp = {
  common: {},
  details: {},
  map: {}
};
const aemContentPropType = PropTypes.shape({
  common: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired
});

MapContainer.propTypes = {
  initialLocation: PropTypes.object,
  results: PropTypes.array,
  aemContent: aemContentPropType,
  google: PropTypes.object,
  activeProvider: PropTypes.object,
  providerCenter: PropTypes.object,
  setActiveProvider: PropTypes.func,
  setProviderCenter: PropTypes.func,
  isMobile: PropTypes.bool,
  setMapDetailsRedirect: PropTypes.func
};

MapContainer.defaultProps = {
  initialLocation: {},
  results: [],
  aemContent: aemContentDefaultProp,
  google: null,
  activeProvider: {},
  setActiveProvider: () => {},
  providerCenter: {},
  setProviderCenter: () => {},
  isMobile: false,
  setMapDetailsRedirect: () => {}
};

const ApiWrapper = GoogleApiWrapper((props) => ({
  apiKey: props.publicKey,
  v: "3.38",
}))(MapContainer);

const MapWrapper = props => (
  <div className="mapWrapper">
    <div className="sr-only">{props.aemContent.map.srText}</div>
    <ApiWrapper {...props} />
  </div>
);

MapWrapper.defaultProps = {
  aemContent: aemContentDefaultProp
};

MapWrapper.propTypes = {
  aemContent: aemContentPropType
};

export default MapWrapper;
