import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './titleSection.scss';

const TitleSection = ({ aemContent, aemCommon, providerCenter, onHideDetails }) => {
  useLazyStyles(styles);

  return (
    <Fragment>
      <section className="titleSection">
        <h1 id="detailsTitleID" className="providerName" >
          {providerCenter.name}
        </h1>
        <button
          className="closeButton"
          onClick={onHideDetails}
        >
          <Svgicon type="close" />
          <span className="sr-only">{aemContent.close}</span>
        </button>
      </section>
      <div id="detailsDistanceID" className="detailDistance">
        <span className="sr-only">{aemCommon.distance}</span>
        <Svgicon type="map_pin" title={aemCommon.distance} />
        {providerCenter.location.distance}
        <span aria-hidden="true">&nbsp;{aemCommon.mileAbbreviation}</span>
        <span className="sr-only">{aemCommon.miles}</span>
      </div>
    </Fragment>
  );
};

TitleSection.propTypes = {
  aemContent: PropTypes.object,
  aemCommon: PropTypes.object,
  providerCenter: PropTypes.object,
  onHideDetails: PropTypes.func
};

TitleSection.defaultProps = {
  aemContent: null,
  aemCommon: null,
  providerCenter: null,
  onHideDetails: () => null
};

export default TitleSection;
