import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import { formatPhoneNumber } from '../../utils/uiUtils.js';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './noResults.scss';

const NoResults = ({ aemContent, retrySearch, type, isMobile }) => {
  const callString = aemContent.moreDetails.replace(/dynamic_number/, formatPhoneNumber(aemContent.number));

  useLazyStyles(styles);

  return (
    <div className="noResults">
      <Svgicon type="no_results" />
      <span className="sr-only">Attention</span>
      <h2 className="noResultsTitle">
        {aemContent.title}
      </h2>
      <p className="noResultsSuggestion">
        {aemContent.suggestion && (
          <Fragment>
            {aemContent.suggestion}
          </Fragment>
        )}
        {aemContent.suggestionAction && (
          <a href="#" className="noResultsLink" {...type === 'serverError' ? { onClick: retrySearch } : {}}>
            <Svgicon type={type === 'serverError' ? 'retry' : 'map_pin'} classes="" />
            <span>{aemContent.suggestionAction}</span>
          </a>
        )}
        {isMobile ? (
          <a href={`tel:${aemContent.number}`} className="noResultsLink">
            <Svgicon type="phone" classes="" />
            <span>{aemContent.call}</span>
          </a>)
          : (
            <Fragment>
              <br />{aemContent.call}<br />
            </Fragment>
          )}
        {callString}
      </p>
    </div>
  );
};

NoResults.propTypes = {
  aemContent: PropTypes.object,
  retrySearch: PropTypes.func,
  type: PropTypes.string,
  isMobile: PropTypes.bool
};

NoResults.defaultProps = {
  aemContent: null,
  retrySearch: () => { },
  type: '',
  isMobile: false
};

export default NoResults;
