import { isNullEmptyOrUndefined } from './defUtils';

export const setToStorage = (key, value) => {
  if (!isNullEmptyOrUndefined(value)) {
    window.sessionStorage.setItem(key, value);
  }
};

export const tryFetchFromStorage = (key) => {
  let savedData = window.sessionStorage.getItem(key);
  if (!isNullEmptyOrUndefined(savedData)) {
    savedData = JSON.parse(savedData);
  }
  return savedData;
};

export const setDataStore = (key, data, storageKey) => {
  let content = tryFetchFromStorage(storageKey);
  content = { ...content, [key]: data };
  setToStorage(storageKey, JSON.stringify(content));
};

export const getDataStore = (key, storageKey) => {
  const content = tryFetchFromStorage(storageKey);
  return !isNullEmptyOrUndefined(content) ? content[key] : null;
};
