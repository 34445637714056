import React from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './backButton.scss';

const BackButton = ({ srText, onClickBack }) => {
  useLazyStyles(styles);

  return (
    <button
      className="backButton"
      onClick={onClickBack}
    >
      <Svgicon type="arrow_left" />
      <span className="sr-only">{srText}</span>
    </button>
  );
};

BackButton.propTypes = {
  onClickBack: PropTypes.func,
  srText: PropTypes.string
};

BackButton.defaultProps = {
  onClickBack: () => {},
  srText: ''
};

export default BackButton;
