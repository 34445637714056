import {
  POST_TYPES,
  NAMESPACES,
  PAGE_DATA_LAYER_NAMES
} from '../constants/analytics';
import PORTALS from '../constants/portals';
import { isNullEmptyOrUndefined } from './defUtils';

let modulePageDataLayerName = PAGE_DATA_LAYER_NAMES.DEFAULT;
let modulePortalName = PORTALS.DEFAULT;

export class AnalyticsQueue {
  // Wait a max of ten seconds
  static MAX_WAIT = 10000;

  // Check every second for _satellite
  static CHECK_EVERY = 1000;

  constructor() {
    this.queue = [];
    this.runningTime = 0;
  }

  // Executes track immediately if possible,
  // else adds analytics data to queue for retry
  queueOrExecute = (namespace, content) => {
    if (window._satellite && window._satellite.track) {
      window._satellite.track(namespace, content);
    } else {
      this.queue.push({ namespace, content });
      // If the analytics queue hasn't started processing, start it
      if (this.runningTime === 0) {
        this.executeQueue();
      }
    }
  };

  executeQueue = () => {
    // If track is available, send all queued
    // items to Adobe analytics, then clear the queue
    if (window._satellite && window._satellite.track) {
      this.queue.forEach(item => {
        window._satellite.track(item.namespace, item.content);
      });

      this.queue = [];
    } else if (this.runningTime < AnalyticsQueue.MAX_WAIT) {
      this.runningTime += AnalyticsQueue.CHECK_EVERY;
      window.setTimeout(this.executeQueue, AnalyticsQueue.CHECK_EVERY);
    }
  };
}

// Instantiate analytics queue immediately, but don't start timers
const analyticsQueue = new AnalyticsQueue();

export const initializePageDataLayer = (portal, aemContent, token) => {
  modulePortalName = portal
    ? portal
    : PORTALS.DEFAULT;

  modulePageDataLayerName = PAGE_DATA_LAYER_NAMES[portal]
    ? PAGE_DATA_LAYER_NAMES[portal]
    : PAGE_DATA_LAYER_NAMES.DEFAULT;

  let pageDataLayer = aemContent[modulePortalName];

  if (isNullEmptyOrUndefined(pageDataLayer)) {
    pageDataLayer = { content: {}, user: {} };
  }

  window[modulePageDataLayerName] = {
    content: pageDataLayer.content,
    user: {
      loginStatus: token ? 'loggedin' : 'not loggedin',
      ...pageDataLayer.user
    }
  };
};

/**
 * injectAnalyticsScript  - Appends environment-specific satellite library script to document head
 */
export const injectAnalyticsScript = () => {
  const satelliteLibScript = document.createElement('script');
  satelliteLibScript.async = true;
  satelliteLibScript.id = 'satLibScript';
  satelliteLibScript.src = window.analycticScriptUrl;

  satelliteLibScript.onload = () => {
    if (window._satellite && window._satellite.pageBottom) {
      window._satellite.pageBottom();
    }
  };

  document.head.appendChild(satelliteLibScript);
};

export const postAnalyticsData = (type, data) => {
  const postObject = { user: window[modulePageDataLayerName].user };
  const content = { ...data.content, ...window[modulePageDataLayerName].content };
  const portalNamespace = NAMESPACES[modulePortalName] || NAMESPACES.DEFAULT;
  let postNamespace = portalNamespace.DEFAULT;

  switch (type) {
    case POST_TYPES.RESULTS_CLICK: {
      postObject.actions = { search: data.search };
      postNamespace = portalNamespace.RESULTS_CLICK;
      break;
    }
    case POST_TYPES.GENERIC_CLICK: {
      postObject.actions = { search: data.search };
      postNamespace = portalNamespace.GENERIC_CLICK;
      break;
    }
    case POST_TYPES.RESULTS_PAGE:
    case POST_TYPES.DETAIL_PAGE: {
      postObject.content = content;
      postObject.actions = { search: data.search };
      break;
    }
    case POST_TYPES.CHANGE_LOCATION_PAGE:
    case POST_TYPES.LANDING_PAGE:
    default: {
      postObject.content = content;
      break;
    }
  }

  // Either execute or add the current analytics post to the queue
  analyticsQueue.queueOrExecute(postNamespace, postObject);
  return analyticsQueue.queue.length === 0;
};
