import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Svgicon from '../svg-icon/svgicon';

export const initMapTextLinkA11y = (link, srText) => {
  const a11yHtml = renderToStaticMarkup(
    <>
      <Svgicon isStaticMarkup={true} type="new_window" classes="googleLinkNewWindowIcon" />
      <span className="sr-only">{srText}</span>
    </>
  );

  link.classList.add('googleLink');
  link.insertAdjacentHTML('beforeend', a11yHtml);
};

export const initGoogleLogoA11y = img => {
  const newWindowIconHtml = renderToStaticMarkup(<Svgicon isStaticMarkup={true} type="new_window" classes="googleLogoNewWindowIcon" />);

  let link = img;
  while (link.tagName !== 'A' && link.parentElement) {
    link = link.parentElement;
  }

  if (link.tagName === 'A') {
    link.style.display = 'block';

    const div = link.parentElement;
    if (div) {
      div.classList.add('gm-style-cc', 'googleLogoContainer');
      img.insertAdjacentHTML('afterend', newWindowIconHtml);
      return;
    }
  }

  console.warn('Provider Search encountered an error while processing the map for accessibility.');
};
