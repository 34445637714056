import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import { formatPhoneNumber } from '../../utils/uiUtils.js';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import url from 'url';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './rollUp.scss';

const Rollup = ({ aemContent, aemCommon, directionsUrl, providerCenter, directionsRef, isMobile, linkNamePrefix }) => {
  const handleClick = (type) => {
    // Post analytics data on clicking links on details page
    postAnalyticsData(POST_TYPES.GENERIC_CLICK, {
      search: {
        linkName: `${linkNamePrefix}fertility search: ${type}`
      }
    });
  };
  const directionUrl = directionsUrl.replace('dynamic_latitude', providerCenter.location.latitude).replace('dynamic_longitude', providerCenter.location.longitude);

  useLazyStyles(styles);

  return (
    <section className="detailButtons">
      <a href={directionUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="detailButton clickable"
        ref={directionsRef}
        onClick={() => handleClick('directions')}
      >
        {isMobile && (
          <Svgicon type="directions" classes="detailIcon" />
        )}
        <span>{aemContent.directions}</span>
        <Svgicon type="new_window" />
        <span className="sr-only">{aemCommon.openNewWindow}</span>
      </a>
      {isMobile && providerCenter.phone && (
        <a href={`tel:${providerCenter.phone}`} className="detailButton" onClick={() => handleClick('call')}>
          <span className="sr-only">{aemContent.overview.phone}</span>
          {isMobile && providerCenter.phone && (
            <Fragment>
              <Svgicon type="phone" classes="detailIcon" />
              <span>{aemContent.call}</span>
            </Fragment>
          )}
        </a>
      )}
      {!isMobile  && providerCenter.phone && (
        <div className="detailButton">
          <span className="sr-only">{aemContent.overview.phone}</span>
          <span>{formatPhoneNumber(providerCenter.phone)}</span>
        </div>
      )}
      <a
        href={isNullEmptyOrUndefined(providerCenter.website) ? '' : url.resolve('https://', providerCenter.website)}
        target="_blank"
        rel="noopener noreferrer"
        className="detailButton clickable"
        onClick={() => handleClick('website')}>
        {isMobile && (
          <Svgicon type="website" classes="detailIcon" />
        )}
        <span>{aemContent.website}</span>
        <Svgicon type="new_window" />
        <span className="sr-only">{aemCommon.openNewWindow}</span>
      </a>
    </section>
  );
};

Rollup.propTypes = {
  aemContent: PropTypes.object,
  aemCommon: PropTypes.object,
  directionsUrl: PropTypes.string,
  providerCenter: PropTypes.object,
  directionsRef: PropTypes.object,
  isMobile: PropTypes.bool,
  linkNamePrefix: PropTypes.string
};

Rollup.defaultProps = {
  aemContent: null,
  aemCommon: null,
  directionsUrl: '',
  providerCenter: null,
  directionsRef: null,
  isMobile: false,
  linkNamePrefix: ''
};

export default Rollup;
