import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import { formatPhoneNumber } from '../../utils/uiUtils.js';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import url from 'url';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './details.scss';

const Details = ({ aemContent, aemCommon, providerCenter, linkNamePrefix }) => {
  // Post analytics data on clicking website footer
  const handleClick = () => {
    postAnalyticsData(POST_TYPES.GENERIC_CLICK, {
      search: {
        linkName: `${linkNamePrefix}fertility search: website footer`
      }
    });
  };

  useLazyStyles(styles);

  return (
    <section className="detailsSection">
      <div className="details">
        <h2 className="detailTitle">{aemContent.overview.address}</h2>
        {providerCenter.address.addressLine1}<br />
        {providerCenter.address.addressLine2 && (
          <Fragment>
            {providerCenter.address.addressLine2}<br />
          </Fragment>
        )}
        {providerCenter.address.city},&nbsp;
        {providerCenter.address.state}&nbsp;
        {providerCenter.address.zipCode}
      </div>
      {providerCenter.phone && (
        <div className="details">
          <h2 className="detailTitle">{aemContent.overview.phone}</h2>
          {formatPhoneNumber(providerCenter.phone)}
        </div>
      )}
      <div className="details">
        <h2 className="detailTitle">{aemContent.overview.website}</h2>
        <a
          className="detailLink"
          href={isNullEmptyOrUndefined(providerCenter.website) ? '' : url.resolve('https://', providerCenter.website)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}>
          <span>
            {providerCenter.website}
          </span>
          <Svgicon type="new_window" />
          <span className="sr-only">{aemCommon.openNewWindow}</span>
        </a>
      </div>
    </section>
  );
};

Details.propTypes = {
  aemContent: PropTypes.object,
  aemCommon: PropTypes.object,
  providerCenter: PropTypes.object,
  linkNamePrefix: PropTypes.string
};

Details.defaultProps = {
  aemContent: null,
  aemCommon: null,
  providerCenter: null,
  linkNamePrefix: ''
};

export default Details;
