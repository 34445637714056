import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import TitleSection from './titleSection.js';
import StaticMap from '../staticMap/staticMap';
import RollUp from './rollUp.js';
import Details from './details.js';
import KEYS from '../../constants/keys';
import uiConstants from '../../constants/ui';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import useLazyStyles from '../../utils/useLazyStyles';
import { useGnFocusRelease } from '../../utils/useGnFocusRelease';
import styles from './searchDetails.scss';

const SearchDetails = ({ aemContent, aemCommon, aemConfig, providerCenter, onHideDetails, isMobile, mapDetailsRedirect, linkNamePrefix, deviceType, googleMapsConfigs }) => {
  const directionsRef = useRef();
  const directionsUrl = aemConfig.mapLinks[deviceType];

  const { focusReleaseElement } = useGnFocusRelease();
  const isActiveFocusTrap = !focusReleaseElement;

  useLazyStyles(styles);

  useEffect(() => {
    focusDirections();
    // Post analytics data for results page
    postAnalyticsData(POST_TYPES.RESULTS_PAGE, {
      content: { pageName: 'search detail' },
      search: {
        searchResultTitle: providerCenter.name,
        searchResultArea: mapDetailsRedirect ? 'map' : 'text'
      }
    });
  }, []);

  const _handleEscapeKey = e => {
    if (e.key === KEYS.ESCAPE) {
      onHideDetails();
      e.stopPropagation();
    }
  };

  const focusDirections = () => {
    directionsRef.current && directionsRef.current.focus();
  };

  return (
    <>
      {isMobile &&
        <div className="PS_modalBackdrop"></div>
      }
      <FocusTrap active={isActiveFocusTrap}>
        <div className="searchDetails" onKeyDown={_handleEscapeKey} tabIndex="-1" role="dialog" aria-modal="true" aria-labelledby={uiConstants.a11yDetailsTitleID} aria-describedby={uiConstants.a11yDetailsMilesID}>
          <TitleSection aemContent={aemContent} aemCommon={aemCommon} providerCenter={providerCenter} onHideDetails={onHideDetails} />
          <RollUp aemContent={aemContent} aemCommon={aemCommon} directionsUrl={directionsUrl} providerCenter={providerCenter} directionsRef={directionsRef} isMobile={isMobile} linkNamePrefix={linkNamePrefix} />
          <StaticMap cssClasses="detailMap" coordinates={providerCenter.location} marker={true} scale={1} googleMapsConfigs={googleMapsConfigs} />
          <Details aemContent={aemContent} aemCommon={aemCommon} providerCenter={providerCenter} linkNamePrefix={linkNamePrefix} />
        </div>
      </FocusTrap>
    </>
  );
};

SearchDetails.propTypes = {
  aemContent: PropTypes.object,
  aemCommon: PropTypes.object,
  aemConfig: PropTypes.object,
  providerCenter: PropTypes.object,
  onHideDetails: PropTypes.func,
  isMobile: PropTypes.bool,
  mapDetailsRedirect: PropTypes.bool,
  linkNamePrefix: PropTypes.string,
  deviceType: PropTypes.string
};

SearchDetails.defaultProps = {
  aemContent: null,
  aemCommon: null,
  aemConfig: null,
  providerCenter: null,
  onHideDetails: () => null,
  isMobile: false,
  mapDetailsRedirect: false,
  linkNamePrefix: '',
  deviceType: ''
};

export default SearchDetails;
