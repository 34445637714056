import axios from 'axios';
import { tryFetchFromStorage, setToStorage } from '../utils/storageUtils';
import { isNullEmptyOrUndefined } from '../utils/defUtils';

export default class AEMContentService {

  constructor(aemContentUrl) {
    this.aemContentUrl = aemContentUrl;
   }

  _buildStorageKey = (portalName, lang) => `ogn.providerSearch.aemContent.${portalName}.${lang}`;

  async _request(portalName, lang) {
    const storageKey = this._buildStorageKey(portalName, lang);
    let content = tryFetchFromStorage(storageKey);
    if (isNullEmptyOrUndefined(content)) {
      const path = this.aemContentUrl;
      content = await axios.get(path);
      setToStorage(storageKey, JSON.stringify(content.data));
      return content.data;
    } else {
      return Promise.resolve(content);
    }
  }

  async execute(portalName, lang) {
    const content = await this._request(portalName, lang);
    return content;
  }
}
