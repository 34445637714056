import axios from 'axios';
import { getDataStore, setDataStore } from '../utils/storageUtils';
import { isNullEmptyOrUndefined } from '../utils/defUtils';
import PORTALS from '../constants/portals';

export default class SearchContentService {
  constructor(searchContentUrl, stargateApiUrl) {
    this.searchContentUrl = searchContentUrl;
    this.stargateApiUrl = stargateApiUrl;
  }

  _getApiUrl = (token) => {
    if (isNullEmptyOrUndefined(token)) {
      return this.searchContentUrl;
    } else {
      return this.stargateApiUrl;
    }
  }

  _buildStorageKey = (portalName, lang) => `ogn.providerSearch.searchContent.${portalName}.${lang}`;

  async _request(portalName, lang, token, searchTerm, config) {

    try {
      const storageKey = this._buildStorageKey(portalName, lang);
      let content = getDataStore(searchTerm, storageKey);
      const radius = config.searchDistance;

      if (isNullEmptyOrUndefined(content)) {
        const path = this._getApiUrl(token);
        const payload = {
          searchstring: searchTerm,
          distance: radius
        };

        const headers = {
          Authorization: `Bearer ${token}`
        };

        if (portalName === PORTALS.MYUHC) {
          headers['x-idp'] = config.httpHeaders.xIdp
        }

        content = await axios.post(path, payload,
          isNullEmptyOrUndefined(token) ? {} : { headers });

        if (isNullEmptyOrUndefined(content.data)) {
          throw 'Invalid response';
        }

        setDataStore(searchTerm, content.data, storageKey);
        return content.data;
      } else {
        return Promise.resolve(content);
      }
    } catch (e) {
      return Promise.resolve({ noResultsType: 'serverError' });
    }
  }

  async execute(portalName, lang, token, searchTerm, config) {
    const content = await this._request(portalName, lang, token, searchTerm, config);
    return content;
  }
}
