import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import classnames from 'classnames';
import Svgicon from '../svg-icon/svgicon';
import KEYS from '../../constants/keys';
import uiConstants from '../../constants/ui';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import useLazyStyles from '../../utils/useLazyStyles';
import { useGnFocusRelease } from '../../utils/useGnFocusRelease';
import styles from './detailsCard.scss';

const DetailsCard = ({ aemContent, activeProvider, setActiveProvider, providerCenter, setProviderCenter, setMapDetailsRedirect }) => {
  const containerRef = useRef(null);
  const headingRef = useRef(null);
  const detailsButtonRef = useRef(null);
  // cache active provider so information is retained in transition
  const [provider, setProvider] = useState({});
  const [isStyleUp, setStyleUp] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isActiveFocusTrap, setIsActiveFocusTrap] = useState(false);
  const { focusReleaseElement } = useGnFocusRelease(isActiveFocusTrap);

  useLazyStyles(styles);

  useEffect(() => {
    if (focusReleaseElement !== void 0) {
      if (isActiveFocusTrap === !!focusReleaseElement) {
        setIsActiveFocusTrap(!focusReleaseElement);
      }
    }
  }, [focusReleaseElement]);

  useEffect(() => {
    // force repaint for up transition after using the close button
    if (containerRef.current) {
      containerRef.current.scrollTop;  // eslint-disable-line
    }

    if (activeProvider.address) {
      setProvider(activeProvider);
    }
  }, [activeProvider]);

  useEffect(() => {
    // move dialog up if needed
    if (provider.address) {
      !isStyleUp && setStyleUp(true);
    }
  }, [provider]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
  }, [isStyleUp]);

  useEffect(() => {
    // focus heading when clicking map pins and when opening dialog
    if (isOpen) {
      headingRef.current.focus();
      setIsActiveFocusTrap(true);
    }
  }, [isOpen, provider]);

  useEffect(() => {
    // focus details button after closing details dialog
    if (isOpen && !isActiveFocusTrap && isNullEmptyOrUndefined(providerCenter.address)) {
      detailsButtonRef.current.focus();
      setIsActiveFocusTrap(true);
    }
  }, [providerCenter]);

  const onClickClose = () => {
    setIsActiveFocusTrap(false);
    setOpen(false);
    setStyleUp(false);
  };

  const onClickDetails = () => {
    setIsActiveFocusTrap(false);
    setMapDetailsRedirect(true);
    setProviderCenter(activeProvider);

    // Post analytics data on opening details page(with map interaction- mobile)
    postAnalyticsData(POST_TYPES.RESULTS_CLICK, {
      search: {
        searchResultTitle: activeProvider.name,
        searchResultArea: 'map'
      }
    });
  };

  const onTransitionEnd = () => {
    document.body.style.overflowY = 'auto';
    if (isStyleUp) {
      setIsActiveFocusTrap(true);
      setOpen(true);
    } else {
      // clear provider content once off screen (to prevent tabbing if mounted)
      setProvider({});
      setActiveProvider({});
    }
  };

  const allowOutsideClick = e => {
    // prevent focus movement on mousedown
    if (e.type === 'mousedown') {
      return false;
    }
    // don't close when clicking map pins
    if (/img/i.test(e.target.tagName)) {
      return true;
    }
    onClickClose();
  };

  const onTransitionEndDetailsButton = e => {
    // prevent the focus trap when combined with css transitions from taking focus from the details dialog
    e.stopPropagation();
  };

  const onEscapeDown = e => {
    if (e.key === KEYS.ESCAPE) {
      onClickClose();
    }
  };

  return (
    <FocusTrap
      active={isActiveFocusTrap}
      focusTrapOptions={{ allowOutsideClick }}
    >
      <section
        ref={containerRef}
        aria-describedby={uiConstants.a11yDialogDetailsId}
        role="dialog"
        className={classnames('detailsCard', { detailsCardUp: isStyleUp, detailsCardDown: !isStyleUp })}
        onKeyDown={onEscapeDown}
        onTransitionEnd={onTransitionEnd}
      >
        {provider.address && (<>
          <button
            className="closeButton"
            onClick={onClickClose}
          >
            <Svgicon type="close" />
            <span className="sr-only">{aemContent.close}</span>
          </button>
          <h3
            id={uiConstants.a11yDialogDetailsId}
            ref={headingRef}
            tabIndex="-1"
            className="cardTitle noFocus"
          >{provider.name}</h3>
          <p className="cardDetails">
            {provider.address.addressLine1},&nbsp;
            {provider.address.addressLine2 && (
              <>
                {provider.address.addressLine2},&nbsp;
              </>
            )}
            {provider.address.city}, {provider.address.state} {provider.address.zipCode}
          </p>
          <a tabIndex="-1" href={`tel:${provider.phone}`} className="cardContact">
            {provider.phone}
            <span className="sr-only">{provider.phone}</span>
          </a>
          <button
            className="PS_btn cardDetailsButton"
            ref={detailsButtonRef}
            onClick={onClickDetails}
            onTransitionEnd={onTransitionEndDetailsButton}
          >Details</button>
        </>)}
      </section>
    </FocusTrap>
  );
};

DetailsCard.propTypes = {
  activeProvider: PropTypes.object,
  aemContent: PropTypes.object,
  setActiveProvider: PropTypes.func,
  providerCenter: PropTypes.object,
  setProviderCenter: PropTypes.func,
  setMapDetailsRedirect: PropTypes.func
};

DetailsCard.defaultProps = {
  activeProvider: {},
  aemContent: {},
  providerCenter: {},
  setActiveProvider: () => {},
  setProviderCenter: () => {},
  setMapDetailsRedirect: () => {}
};

export default DetailsCard;
