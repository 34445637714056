import React from 'react';
import ReactDOM from 'react-dom';
import SearchRoot from './components/searchRoot.js';
import { injectAnalyticsScript } from '../app/utils/analytics-utils';
import { libraryName } from '../../webpack/webpack.constants';
import styles from './index.scss';
import { isNullEmptyOrUndefined } from './utils/defUtils.js';

const fullPageContainerClass = 'ognGlPsFullPageContainer';
const initWarning = 'Provider Search unable to initialize.  Please provide a valid root element.';

export const initializeProviderSearch = ({
  lang,
  useAnalyticsScript = true,
  ...rest
}) => {
  const config = {
    language: lang,
    ...rest
  };
  const rootElement = document.querySelector('provider-search');

  useAnalyticsScript && injectAnalyticsScript();

  if (rootElement) {
    styles.use();
    ReactDOM.render(
      <SearchRoot {...config} />,
      rootElement
    );

  } else {
    console.warn(initWarning); // eslint-disable-line
  }
};

export const initializeGlProviderSearch = ({
  providerSearchConfig,
  rootElement,
  rootElementSelector,
  useAnalyticsScript = false,
  ...rest
}) => {
  const mountNode = rootElement
    ? rootElement
    : rootElementSelector
      ? document.querySelector(rootElementSelector)
      : null;

  let config = {
    glFullPageContainer: rootElement || null,
    ...rest
  };

  if (providerSearchConfig) {
    config = { ...config, ...providerSearchConfig };
  }

  if(isNullEmptyOrUndefined(config.brand)){
    // If no brand is specified in either the parameters or the GL config, default to optum
    config.brand = "optum";
  }

  useAnalyticsScript && injectAnalyticsScript();

  if (mountNode) {
    rootElement && mountNode.classList.add(fullPageContainerClass);

    styles.use();
    ReactDOM.render(
      <SearchRoot {...config} />,
      mountNode
    );

    return {
      destroy() {
        ReactDOM.unmountComponentAtNode(mountNode);
        styles.unuse();
        rootElement && mountNode.classList.remove(fullPageContainerClass);
      }
    };
  } else {
    console.warn(initWarning);  // eslint-disable-line
  }
};

window[libraryName] = {
  initializeProviderSearch,
  initializeGlProviderSearch
};
