/**
 * trim - Trims a string if it exists, else returns blank string
 *
 * @param {String} str     String to trim spaces from
 * @returns {String}
 */
export const trim = str => (str && str.length ? str.replace(/^\s+|\s+$/gm, '') : '');

/**
 * isNullEmptyOrUndefined - Checks to see if the parameter is null,
 * empty, or undefined.
 *
 * @param {*} str     Item to verify
 * @returns {Boolean}
 */
export const isNullEmptyOrUndefined = obj => {
  return obj === null || typeof obj === 'undefined' || obj === 'undefined' || (typeof obj === 'string' && trim(obj) === '');
};

export default {
  trim,
  isNullEmptyOrUndefined
};
