import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import HasResults from './hasResults';
import NoResults from './noResults';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import DetailsCard from '../detailsCard/detailsCard';
import { filterSearchCategory } from '../../utils/uiUtils';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './searchResults.scss';

const SearchResults = ({
  activeProvider,
  aemContent,
  changeSearch,
  isMobile,
  mapDetailsRedirect,
  onChangeSearch,
  providerCenter,
  retrySearch,
  searchContent,
  searchTerm,
  setActiveProvider,
  setMapDetailsRedirect,
  setProviderCenter
}) => {
  const searchFound = (isMobile ? aemContent.results.numberFoundMobile : aemContent.results.numberFound).replace('dynamic_data', searchContent.results ? searchContent.results.length : 0);

  const searchFoundRef = useRef(null);
  const searchLinkRef = useRef(null);

  useLazyStyles(styles);

  useEffect(() => {
    if (!isNullEmptyOrUndefined(providerCenter.address)) { return; }

    // Post analytics data for results page
    postAnalyticsData(POST_TYPES.RESULTS_PAGE, {
      content: { pageName: 'search results' },
      search: {
        searchTerm,
        searchCategory: filterSearchCategory(searchTerm, searchContent.noResultsType),
        searchResults: !isNullEmptyOrUndefined(searchContent.results) ? searchContent.results.length : 0
      }
    });
  }, [searchContent, providerCenter]);

  useEffect(() => {
    if (changeSearch) {
      searchLinkRef.current && searchLinkRef.current.focus();
    } else {
      searchFoundRef.current && searchFoundRef.current.focus();
    }
  }, [changeSearch]);

  const getNoResultsContent = () => {
    const noResultsType = !isNullEmptyOrUndefined(searchContent.noResultsType) ? searchContent.noResultsType : 'serverError';
    return { aemContent: aemContent.results.noResults[noResultsType], type: noResultsType };
  };

  return (
    <section className="resultsContainer">
      {isMobile && (
        <Fragment>
          <h1 className="resultsTitle">{aemContent.results.resultsTitle}</h1>
          <div className="resultsHeader">
            <button ref={searchLinkRef} className="searchLink" onClick={onChangeSearch}>
              <Svgicon type="map_pin" />
              {searchTerm}&nbsp;{aemContent.results.changeSearch}
            </button>
            <div ref={searchFoundRef} tabIndex={-1} className="searchFound mobile">{searchFound}</div>
          </div>
        </Fragment>
      )}
      {!isMobile && (
        <div ref={searchFoundRef} tabIndex={-1} className="resultsHeader noFocus">
          <div className="searchFound desktop">{searchFound}</div>
          <div className="searchTerm">{searchTerm}</div>
        </div>
      )}
      {
        (searchContent.results && searchContent.results.length > 0) ? (
          <HasResults
            activeProvider={activeProvider}
            aemContent={aemContent}
            mapDetailsRedirect={mapDetailsRedirect}
            providerCenter={providerCenter}
            results={searchContent.results}
            setActiveProvider={setActiveProvider}
            setMapDetailsRedirect={setMapDetailsRedirect}
            setProviderCenter={setProviderCenter}
          />
        ) : (
          <NoResults
            {...getNoResultsContent()}
            isMobile={isMobile}
            retrySearch={retrySearch}
          />
        )
      }
      {isMobile && !isNullEmptyOrUndefined(activeProvider.address) &&
        (<DetailsCard
          activeProvider={activeProvider}
          aemContent={aemContent.details}
          providerCenter={providerCenter}
          setActiveProvider={setActiveProvider}
          setMapDetailsRedirect={setMapDetailsRedirect}
          setProviderCenter={setProviderCenter}
        />)}
    </section>
  );
};

SearchResults.propTypes = {
  activeProvider: PropTypes.object,
  aemContent: PropTypes.object,
  changeSearch: PropTypes.bool,
  isMobile: PropTypes.bool,
  mapDetailsRedirect: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  providerCenter: PropTypes.object,
  retrySearch: PropTypes.func,
  searchContent: PropTypes.object,
  searchTerm: PropTypes.string,
  setActiveProvider: PropTypes.func,
  setMapDetailsRedirect: PropTypes.func,
  setProviderCenter: PropTypes.func
};

SearchResults.defaultProps = {
  activeProvider: {},
  aemContent: null,
  changeSearch: false,
  isMobile: false,
  mapDetailsRedirect: false,
  onChangeSearch: () => null,
  providerCenter: {},
  retrySearch: () => {},
  searchContent: null,
  searchTerm: '',
  setActiveProvider: () => {},
  setMapDetailsRedirect: () => {},
  setProviderCenter: () => {}
};

export default SearchResults;
