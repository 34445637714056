import { SEARCH_CATEGORY, REGREX } from '../constants/analytics';

export const getElementPromise = (selectorFn, delay = 500) => {
  let id;
  const promise = new Promise(resolve => {
    id = setInterval(() => {
      const element = selectorFn();
      if (element) {
        clearInterval(id);
        resolve(element);
      }
    }, delay);
  });

  return { id, promise };
};

export const filterSearchCategory = (searchString, noResultsType) => {
  if (noResultsType === 'unknown') { return SEARCH_CATEGORY.NA; }

  let reg = new RegExp(REGREX.ZIP);

  if (reg.test(searchString)) { return SEARCH_CATEGORY.ZIP; }

  reg = new RegExp(REGREX.CS);
  if (reg.test(searchString)) { return SEARCH_CATEGORY.CS; }

  reg = new RegExp(REGREX.CSZ);
  if (reg.test(searchString)) { return SEARCH_CATEGORY.CSZ; }

  return SEARCH_CATEGORY.NA;
};

export const findElementByTextContent = (selector, text) => Array.from(document
  .querySelectorAll(selector))
  .find(el => el.textContent.includes(text));

export const formatPhoneNumber = phoneNumberStr => {
  let cleaned = phoneNumberStr.replace(/\D/g, '');  // remove non digits
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    cleaned = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  } else {
    cleaned = phoneNumberStr;
  }
  return cleaned;
};

export default {
  getElementPromise,
  filterSearchCategory,
  findElementByTextContent,
  formatPhoneNumber
};
