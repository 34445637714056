import { useLayoutEffect } from 'react';

export default function useLazyStyles(styles, isStaticMarkup = false) {
  if (isStaticMarkup) {
    return;
  }

  useLayoutEffect(() => {
    styles.use();

    return () => {
      styles.unuse();
    };
  }, []);
}
