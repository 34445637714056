import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapService from '../../services/googleMap';
import { isNullEmptyOrUndefined } from '../../utils/defUtils';
import classNames from 'classnames';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './staticMap.scss';

const StaticMap = ({ cssClasses, coordinates, marker, containerSize, scale, googleMapsConfigs }) => {
  const [map, setMap] = useState('');
  const mapContainerRef = useRef();

  useLazyStyles(styles);

  useEffect(() => {
    const mapService = new GoogleMapService(googleMapsConfigs);
    containerSize = isNullEmptyOrUndefined(containerSize) ?
      { width: mapContainerRef.current.offsetWidth, height: mapContainerRef.current.offsetHeight } : containerSize;
    mapService.execute(coordinates, marker, containerSize, scale).then((image) => {
      setMap(image);
    });
  }, []);

  return (<div ref={mapContainerRef} className={classNames('staticMapContainer', cssClasses)} >
    <img className="staticMapImage" src={map} alt="" />
  </div>);
};

StaticMap.propTypes = {
  cssClasses: PropTypes.string,
  coordinates: PropTypes.object,
  marker: PropTypes.bool,
  containerSize: PropTypes.object,
  scale: PropTypes.number
};

StaticMap.defaultProps = {
  cssClasses: '',
  coordinates: null,
  marker: false,
  containerSize: null,
  scale: 1
};

export default StaticMap;
