import React from 'react';
import PropTypes from 'prop-types';
import Svgicon from '../svg-icon/svgicon';
import classnames from 'classnames';
import { postAnalyticsData } from '../../utils/analytics-utils';
import { POST_TYPES } from '../../constants/analytics';
import useLazyStyles from '../../utils/useLazyStyles';
import styles from './hasResults.scss';

const HasResults = ({
  activeProvider,
  aemContent,
  results,
  setActiveProvider,
  setMapDetailsRedirect,
  setProviderCenter
}) => {
  const aemCommon = aemContent.common;
  const _showDetails = (e, providerCenter) => {
    e.preventDefault();

    setActiveProvider({});
    setProviderCenter(providerCenter);
    setMapDetailsRedirect(false);

    // Post analytics data on opening details page(without map interaction)
    postAnalyticsData(POST_TYPES.RESULTS_CLICK, {
      search: {
        searchIndexRange: results.findIndex((item) => item.name === providerCenter.name) + 1,
        searchResultTitle: providerCenter.name,
        searchResultArea: 'text'
      }
    });
  };

  useLazyStyles(styles);

  return (
    <>
      <div className="providerContainer" tabIndex="-1">
        {results.map((item, index) => (
          <div key={index} className={classnames('providerBlock', { ['providerBlock--selected']: item.address === activeProvider.address })}>
            <h2>
              <a
                className="providerTitle"
                href="#"
                onClick={(e) => { _showDetails(e, item); }}
              >
                <span>{item.name}</span>
              </a>
            </h2>
            <section className="addressBlock">
              <p className="address">
                {item.address.addressLine1},&nbsp;
                {item.address.addressLine2 && (
                  <>
                    {item.address.addressLine2},&nbsp;
                  </>
                )}
                {item.address.city},&nbsp;
                {item.address.state}&nbsp;
                {item.address.zipCode}
              </p>
              <div className="distance">
                <Svgicon type="map_pin" title={aemCommon.distance} />
                <span className="sr-only">{aemCommon.distance}</span>
                {item.location.distance}&nbsp;
                <span aria-hidden="true">{aemCommon.mileAbbreviation}</span>
                <span className="sr-only">{aemCommon.miles}</span>
              </div>
            </section>
          </div>
        ))}
      </div>
    </>
  );
};

HasResults.propTypes = {
  activeProvider: PropTypes.object,
  aemContent: PropTypes.object,
  results: PropTypes.array,
  setActiveProvider: PropTypes.func,
  setMapDetailsRedirect: PropTypes.func,
  setProviderCenter: PropTypes.func
};

HasResults.defaultProps = {
  activeProvider: {},
  aemContent: null,
  results: [],
  setActiveProvider: () => {},
  setMapDetailsRedirect: () => {},
  setProviderCenter: () => {}
};

export default HasResults;
